<template>
    <div>
        <b-modal size="lg" :title="$t('general.business_unit')" v-model="modalActive" no-close-on-backdrop>

            <template #default>
                <validation-observer ref="validation">
                    <b-form-group :label="$t('form_data.name')">
                        <validation-provider #default="{ errors }" :name="$t('form_data.name')" rules="required">
                            <b-form-input autofocus v-model="addObject.name"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('config.business_units.business_unit_type')">
                        <validation-provider #default="{ errors }" :name="$t('config.business_units.business_unit_type')" rules="">
                            <v-select  :filter="fuseSearch" v-model="addObject.business_unit_type" appendToBody :options="businessUnitTypes" label="name" :clearable="false" :reduce="item => item.id" :disabled="addObject.confirmed_with_furs">
                                <template #option="{ name }">
                                    <div>{{ name }}</div>
                                </template>
                            </v-select>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>

                    <div class="mb-1">
                        <div style="padding-bottom: 7px;">
                            {{$t('config.business_units.business_unit_code')}}
                            <fa style="padding-left: 5px;" id="business_unit_code" icon="question-circle" color="#72a5d8" />
                        </div>

                        <validation-provider #default="{ errors }" :name="$t('config.business_units.business_unit_code')" rules="required">
                            <b-form-input  placeholder="P1" :disabled="addObject.confirmed_with_furs" v-model="addObject.business_unit_code"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>

                        <b-tooltip triggers="hover" target="business_unit_code">{{$t('config.business_units.business_unit_code_explain')}}</b-tooltip>
                    </div>


                    <div v-if="addressRequired">
                        <div class="row">
                            <div class="col-8">
                                <b-form-group :label="$t('form_data.address')">
                                    <validation-provider #default="{ errors }" :name="$t('form_data.address')" :rules="addressValidationRules">
                                        <b-form-input :disabled="addObject.confirmed_with_furs" v-model="addObject.street"/>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                            <div class="col-4">
                                <b-form-group :label="$t('config.business_units.house_number')">
                                    <div class="d-flex">
                                        <div class="pr-1">
                                            <validation-provider #default="{ errors }" :name="$t('config.business_units.house_number')" :rules="addressValidationRules">
                                                <b-form-input type="number" placeholder="22" :disabled="addObject.confirmed_with_furs" v-model="addObject.house_number"/>
                                                <small class="text-danger">{{errors[0]}}</small>
                                            </validation-provider>
                                        </div>
                                        <div>
                                            <validation-provider #default="{ errors }" :name="$t('config.business_units.house_number_additional')">
                                                <b-form-input placeholder="B" :disabled="addObject.confirmed_with_furs" v-model="addObject.house_number_additional"/>
                                                <small class="text-danger">{{errors[0]}}</small>
                                            </validation-provider>
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <b-form-group :label="$t('form_data.post_code')">
                                    <validation-provider #default="{ errors }" :name="$t('form_data.post_code')" :rules="addressValidationRules">
                                        <b-form-input type="number" :disabled="addObject.confirmed_with_furs" v-model="addObject.postal_code"/>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                            <div class="col-9">
                                <b-form-group :label="$t('form_data.city')">
                                    <validation-provider #default="{ errors }" :name="$t('form_data.city')" :rules="addressValidationRules">
                                        <b-form-input :disabled="addObject.confirmed_with_furs" v-model="addObject.city"/>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <b-form-group :label="$t('config.business_units.cadastral_number')">
                                    <validation-provider #default="{ errors }" :name="$t('config.business_units.cadastral_number')" :rules="addressValidationRules">
                                        <b-form-input type="number" :disabled="addObject.confirmed_with_furs" v-model.number="addObject.cadastral_number"/>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                            <div class="col-4">
                                <b-form-group :label="$t('config.business_units.building_number')">
                                    <div class="d-flex">
                                        <div class="pr-1">
                                            <validation-provider #default="{ errors }" :name="$t('config.business_units.building_number')" :rules="addressValidationRules">
                                                <b-form-input type="number" placeholder="120" :disabled="addObject.confirmed_with_furs" v-model.number="addObject.building_number"/>
                                                <small class="text-danger">{{errors[0]}}</small>
                                            </validation-provider>
                                        </div>
                                        <div>
                                            <validation-provider #default="{ errors }" :name="$t('config.business_units.building_section_number')" :rules="addressValidationRules">
                                                <b-form-input placeholder="0" type="number" :disabled="addObject.confirmed_with_furs" v-model.number="addObject.building_section_number"/>
                                                <small class="text-danger">{{errors[0]}}</small>
                                            </validation-provider>
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                        <div>
                            <b-form-group :label="$t('config.business_units.town')">
                                <validation-provider #default="{ errors }" :name="$t('config.business_units.town')" :rules="addressValidationRules">
                                    <b-form-input :disabled="addObject.confirmed_with_furs" v-model="addObject.community"/>
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </div>
                    </div>

                    <b-form-group :label="$t('general.warehouse')" v-if="$hasTierPermission(Tiers.Premium, Tiers.Standard)">
                        <validation-provider #default="{ errors }" :name="$t('config.warehouses.add_warehouse')" rules="required">
                            <v-select  :filter="fuseSearch" v-model="addObject.warehouse_id" appendToBody :options="warehouses" label="name" :reduce="name => name.id">
                                <template #option="{ name }">
                                    <div>{{ name }}</div>
                                </template>
                            </v-select>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('config.business_units.printer_header')">
                        <validation-provider #default="{ errors }" :name="$t('config.business_units.printer_header')" rules="required">
                            <b-form-textarea  v-model="addObject.printer_header" style="min-height: 150px;"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('config.business_units.printer_footer')">
                        <validation-provider #default="{ errors }" :name="$t('config.business_units.printer_footer')" rules="required">
                            <b-form-textarea  v-model="addObject.printer_footer" style="min-height: 150px;"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('config.business_units.header_logo')">
                        <validation-provider #default="{ errors }" :name="$t('config.business_units.header_logo')">
                            <b-button  @click="isOpen = !isOpen" variant="primary">{{ $t('config.business_units.choose_logo') }}</b-button>
                            <div class="position-relative" v-if="selected.status === 2 && !isOpen">
                                <img :src="`/api/client/v1/logos/${selected.id}/200`"
                                     style="max-width: 100%;display: block; margin-left: auto; margin-right: auto;" class="rounded-lg" alt="Image"/>
                                <b-button style="position: absolute;top: -10px; right: 250px"  variant="danger" @click="removeLogo">
                                    <feather-icon
                                        icon="Trash2Icon"
                                    />
                                </b-button>
                            </div>

                            <b-collapse
                                id="collapse"
                                v-model="isOpen"
                                class="menu-content"
                            >
                                <div class=" row">

                                    <div v-for="logo in logos" :key="logo.id">
                                        <b-card

                                            v-bind:class="[(selected.id === logo.id)?'selectedBorder' : 'noBorder']"
                                            @click="toggleSelected(logo);"
                                            style="object-fit:contain; text-align: center; justify-content: center; height: 140px; width: 140px; margin: 20px;">
                                            <img :src="`/api/client/v1/logos/${logo.id}/100`"
                                                 style="display: inline-block; vertical-align:middle; height:100%; object-fit: scale-down; justify-content: center; text-align: center; max-height: 100px; max-width: 100px;" class="rounded-sm" alt="Image"/>
                                        </b-card>
                                    </div>

                                </div>
                            </b-collapse>

                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                </validation-observer>

                <div style="font-weight: bold; font-size: 15px;" class="mb-1">
                    {{$t('config.business_units.bill_config')}}
                </div>
                <b-form-checkbox switch v-model="addObject.receipt_config.table_and_room">
                    {{$t('config.printer_configs.table_and_room')}}
                </b-form-checkbox>
                <b-form-checkbox switch v-model="addObject.receipt_config.add_x_to_quantity">
                    {{$t('config.printer_configs.add_x_to_quantity')}}
                </b-form-checkbox>
                <b-form-checkbox switch v-model="addObject.receipt_config.additional_spaces">
                    {{$t('config.printer_configs.additional_spaces')}}
                </b-form-checkbox>
                <b-form-checkbox switch v-model="addObject.receipt_config.payment_type_table">
                    {{$t('config.printer_configs.payment_type_table')}}
                </b-form-checkbox>
                <b-form-checkbox class="ml-2" v-if="addObject.receipt_config.payment_type_table" switch v-model="addObject.receipt_config.payment_type_table_header">
                    {{$t('config.printer_configs.payment_type_table_header')}}
                </b-form-checkbox>
                <b-form-checkbox switch v-model="addObject.receipt_config.print_header_lines_table">
                    {{$t('config.printer_configs.print_header_lines_table')}}
                </b-form-checkbox>
                <b-form-group :label="$t('config.printer_configs.line_spacing')">
                    <validation-provider #default="{ errors }" :name="$t('config.printer_configs.line_spacing')" rules="">
                        <v-select v-model="addObject.receipt_config.line_spacing" appendToBody :options="lineSpacings" label="name" :reduce="name => name.id" :clearable="false">
                            <template #option="{ name }">
                                <div>{{ name }}</div>
                            </template>
                        </v-select>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
            </template>

            <template #modal-footer>
                <b-button variant="primary" @click="validate">
                    <span v-if="editing">
                        <feather-icon icon="EditIcon" class="mr-50"/>
                        <span class="align-middle">{{ $t('general.edit') }}</span>
                    </span>
                    <span v-else>
                        <feather-icon icon="PlusIcon" class="mr-50"/>
                        <span class="align-middle">{{ $t('general.add') }}</span>
                    </span>
                </b-button>
            </template>

        </b-modal>

        <LogoModal ref="addModal" @addLogo="addLogo" />
    </div>
</template>
<script>
    import {
        BButton,
        BFormGroup,
        BFormInput,
        BModal,
        BFormTextarea,
        BCollapse,
        VBToggle,
        BCard,
        BTooltip, BFormCheckbox
    } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import vSelect from 'vue-select'
    import Fuse from 'fuse.js'
    import * as Sentry from '@sentry/vue'
    import LogoModal from '@/views/Logo/LogoModal.vue'
    import {Tiers} from '@/libs/enums/Tiers'

    export default {
        directives:{
            'v-b-toggle': VBToggle
        },
        components: {
            BFormCheckbox,
            BTooltip,
            LogoModal,
            BCard,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BFormTextarea,
            BCollapse,
            vSelect,
            ValidationProvider,
            ValidationObserver
        },
        props:{
            warehouses:{
                Type:Array,
                default:[]
            }
        },
        data() {
            return {
                isActive: false,
                isOpen: false,
                selected: {
                    id: '',
                    filename: '',
                    display_name: '',
                    status: '',
                    created_at: ''
                },
                logos : [],
                modalActive: false,
                addObject: {},
                required,
                editing: false,
                businessUnitTypes: [
                    {id: '', name: 'Nepremični poslovni prostor'},
                    {id: 'A', name: 'A - Premičen objekt (npr. prevozno sredstvo, premična stojnica)'},
                    {id: 'B', name: 'B - Objekt na stalni lokaciji (npr. stojnica na tržnici, kiosk)'},
                    {id: 'C', name: 'C - Posamezna elektronska naprava za izdajo računov'}
                ]
            }
        },
        methods: {
            removeLogo() {
                this.addObject.logo_id = ''
                this.selected = {
                    id: '',
                    filename: '',
                    display_name: '',
                    status: '',
                    created_at: ''
                }
            },

            setSelected(id) {
                if (this.logos.find(el => el.id === id)) {
                    this.selected = this.logos.find(el => el.id === id)
                }
            },
            toggleSelected(el) {
                if (this.selected.status === 2) {
                    this.selected = {
                        id: '',
                        filename: '',
                        display_name: '',
                        status: '',
                        created_at: ''
                    }
                    this.addObject.logo_id = ''
                    return
                }
                this.selected = el
                this.addObject.logo_id = el.id
            },
            addLogo(logo) {
                this.addObject.logo_id = logo
            },
            async loadLogos() {
                try {
                    const response = await this.$http.get('/api/client/v1/logos/')
                    this.logos = response.data ?? []
                } catch (error) {
                    this.$printError(error.response)
                }
            },
            fuseSearch(options, search) {
                const fuse = new Fuse(options, {
                    keys: ['name'],
                    shouldSort: true
                })
                return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
            },
            open(data = null) {
                this.lineSpacings = this.line_spacings
                this.isOpen = false
                this.selected = {}
                if (data !== null) {
                    this.addObject = JSON.parse(JSON.stringify(data))
                    this.editing = true
                } else {
                    this.editing = false
                    this.addObject = {
                        name: '',
                        business_unit_type: '',
                        street: '',
                        cadastral_number: null,
                        building_number: null,
                        building_section_number: null,
                        house_number: '',
                        house_number_additional: '',
                        community: '',
                        postal_code: '',
                        city: '',
                        business_unit_code: '',
                        enable_closing_time:false,
                        closing_time: new Date(1970, 1, 1),
                        warehouse_id:'',
                        printer_footer: '',
                        printer_header: '',
                        logo_id:'',
                        receipt_config: {
                            table_and_room: false,
                            add_x_to_quantity: false,
                            payment_type_table: false,
                            payment_type_table_header: false,
                            additional_spaces : false,
                            print_header_lines_table: false,
                            line_spacing: 1
                        }
                    }
                }
                this.setSelected(this.addObject.logo_id)
                this.modalActive = true
            },
            validate() {
                this.$refs.validation.validate().then((success) =>  {
                    if (success) {
                        if (this.editing) this.edit()
                        else this.add()
                    }
                })
            },

            async add() {
                try {
                    const response = await this.$http.post('/api/client/v1/business_units/', this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('print.success.add'))
                } catch (err) {
                    if (err && err.response && err.response.data && (err.response.data.error === 'BusinessUnitCodeExists')) {
                        this.$printSuccess(this.$t('print.warning.business_unit_name_already_exists'))
                    } else {
                        Sentry.captureException(err)
                        this.$printError(this.$t('print.error.on_add'))
                    }
                }
            },
            async edit() {
                try {
                    await this.$http.put(`/api/client/v1/business_units/${this.addObject.id}`, this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    if (err && err.response && err.response.data && (err.response.data.error === 'BusinessUnitCodeExists')) {
                        this.$printSuccess(this.$t('print.warning.business_unit_name_already_exists'))
                    } else {
                        this.$printError(this.$t('print.error.on_add'))
                    }
                }
            }

        },
        computed: {
            lineSpacings() {
                return [
                    {id: 0, name: `${this.$t('config.printer_configs.small')}`},
                    {id: 1, name: this.$t('config.printer_configs.medium')},
                    {id: 2, name: this.$t('config.printer_configs.large')}
                ]
            },
            Tiers() {
                return Tiers
            },
            clientData() {
                return this.$store.getters['client/clientData']
            },
            addressRequired() {
                return (this.addObject.business_unit_type === '')
            },
            addressValidationRules() {
                return this.addressRequired ? 'required' : ''
            }
        },
        mounted() {
            this.loadLogos()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.selectedBorder{
  border: 2px solid #7367f0;
}

.noBorder{
  border: 2px solid transparent;
}
</style>